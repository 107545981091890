const arrow = document.querySelector(".welcome__arrow");
const welcome = document.querySelector(".welcome__img");
const menuBtn = document.querySelector(".navmobile__button");
const menuMobile = document.querySelector(".navmobile__list");
const menuExitBtn = document.querySelector(".navmobile__list--exit");
const currentlyYear = document.querySelector(".footer__year");
const menuLink = document.querySelector(".navmobile__link");
const allMenuLink = document.querySelectorAll(".navmobile__link");
const allMenuDesktopLink = document.querySelectorAll(".navdesktop__link");
const menuDesktop = document.querySelector(".navdesktop");
const scrollSpy = document.querySelectorAll("section");
const questionMark = document.querySelectorAll(".linen__questionmark");
const answersCard = document.querySelectorAll(".linen__answer");
const linenCards = document.querySelectorAll(".linen__card");
const root = document.querySelector(":root");

// delete question mark from linen cards

for (let i = 0; i < linenCards.length; i++) {
	linenCards[i].addEventListener("mouseleave", () => {
		questionMark[i].style.display = "none";
		linenCards[i].style.filter = "grayscale(60%)";

		setTimeout(answer, 500);

		function answer() {
			answersCard[i].style.display = "block";
		}
	});
}

// SCROLLSPY function

const handleScrollSpy = () => {
	if (document.body.classList.contains("mainpage")) {
		const sections = [];

		scrollSpy.forEach((section) => {
			if (window.scrollY <= section.offsetTop + section.offsetHeight - 110) {
				sections.push(section);

				const activeSection = document.querySelector(
					`[href*="${sections[0].id}"]`
				);
				allMenuDesktopLink.forEach((item) =>
					item.classList.remove("navdesktop__link--active")
				);

				activeSection.classList.add("navdesktop__link--active");
			}
		});
	}
};

// menudesktop opacity

const opacityMenuDesktop = () => {
	if (window.scrollY > 100) {
		menuDesktop.style.opacity = "0.7";
	} else {
		menuDesktop.style.opacity = "1";
	}
};

// button mobile = () => {
const menuBtnPosition = () => {
	if (window.scrollY > 100) {
		menuBtn.style.top = "4rem";
	} else {
		menuBtn.style.top = "1.5rem";
	}
};

// year download

const date = new Date();
const year = date.getFullYear();

currentlyYear.textContent = ` ${year}`;

const menuMobileOpen = () => {
	if (menuMobile.classList.contains("navmobile__list--active")) {
		menuMobile.classList.remove("navmobile__list--active");
	} else {
		menuMobile.classList.add("navmobile__list--active");
	}

	navItemsAnimation();
};

const navItemsAnimation = () => {
	let delayTime = 0;
	allMenuLink.forEach((link) => {
		link.classList.toggle("navmobile__link--animation");
		link.style.animationDelay = "." + delayTime + "s";
		delayTime++;
	});
};

// DELETE ANIMATION AND QUIT FROM MENU WHEN YOU CLICKED LINK

const navItemsAnimationRemove = () => {
	allMenuLink.forEach((link) => {
		link.classList.remove("navmobile__link--animation");
	});
};

const menuMobileQuit = () => {
	menuMobile.classList.remove("navmobile__list--active");
	navItemsAnimationRemove();
};

for (let i = 0; i < allMenuLink.length; i++) {
	allMenuLink[i].addEventListener("click", menuMobileQuit);
}

// window.addEventListener("scroll", menuBtnPosition);
window.addEventListener("scroll", opacityMenuDesktop);
window.addEventListener("scroll", handleScrollSpy);
menuBtn.addEventListener("click", menuMobileOpen);
menuExitBtn.addEventListener("click", menuMobileOpen);
arrow.addEventListener("click", () => {
	welcome.style.display = "none";
	root.style.overflowY = "auto";
});
